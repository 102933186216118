import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { ErrorDialogComponent } from 'app/shared/dialogs/error-dialog/error-dialog.component';
import { BehaviorSubject, catchError, lastValueFrom, Observable, switchMap, take, tap } from 'rxjs';
import { ApiService } from 'app/shared/apis/api.service';
import { ProductCompanyModel } from './product-company.model';
import { IFilterPagination } from 'app/shared/interfaces/filter-interface';
import { IPagination } from 'app/shared/interfaces/pagination.dto';
import { AuthService } from 'app/core/auth/auth.service';
import { BaseService } from 'app/core/services/base.service';
import { map } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class ProductCompanyService extends BaseService<ProductCompanyModel> {
    endpoind = 'productCompany';

    constructor(api: ApiService, _matDialog: MatDialog) {
        super(api, _matDialog, 'productCompany');
    }
}
