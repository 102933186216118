import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CompanyService } from 'app/modules/pce/company/company.service';
import { catchError, forkJoin, of } from 'rxjs';

export const companyResolver = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const companyService = inject(CompanyService);
    const router = inject(Router);
    if (!route.params['company_slug']) return null;
    return companyService.getBySlug(route.params['company_slug']).pipe(
        catchError((_) => {
            router.navigate(['/']);
            return of(null);
        }),
    );
};
