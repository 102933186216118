import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
export interface DialogData {
    title: string;
    message: string;
  }
@Component({
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    CommonModule
    ]
})


export class ErrorDialogComponent implements OnInit {
    _data;
  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
      this._data = data;
   }

  ngOnInit(): void {
  }
  close(): void {
    this.dialogRef.close(false);
  }
  salvar(): void {
    this.dialogRef.close(false);
  }

}
