import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { DevstroupeConfig, DevstroupeConfigService, Scheme, Theme } from '@devstroupe/services/config';
import { SettingsService } from 'app/shared/settings/settings.service';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule, NgClass, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { DevstroupeDrawerComponent } from '@devstroupe/components/drawer';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
    selector     : 'settings',
    templateUrl  : './settings.component.html',
    styles       : [
        `
            settings {
                position: static;
                display: block;
                flex: none;
                width: auto;
            }

            @media (screen and min-width: 1280px) {

                empty-layout + settings .settings-cog {
                    right: 0 !important;
                }
            }
        `
    ],
    imports        : [MatButtonModule, MatMenuModule, NgIf, MatIconModule, NgClass, MatDividerModule, DevstroupeDrawerComponent, CommonModule, MatSlideToggleModule],
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'settings',
})
export class SettingsComponent implements OnInit, OnDestroy
{
    config: DevstroupeConfig;

    settings: any = {
        ignore_qty_possible_to_measure: false,
        ignore_locations_selected_to_measure: false

    };
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    @ViewChild('settingsDrawer') public settingsDrawer: any;
    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private _devstroupeConfigService: DevstroupeConfigService,
        private _settingsService: SettingsService,
        private _changeDetectorRef: ChangeDetectorRef,
    )
    {

        this._settingsService.settings.subscribe((settings) => {
            this.settings = settings;

            this._changeDetectorRef.markForCheck();
        })
    }

    ngOnInit(): void
    {
        // this.settings.ignore_qty_possible_to_measure = localStorage.getItem('ignore_qty_possible_to_measure') == 'true';
        // this.setIgnoreQtyPossibleToMeasure(this.settings.ignore_qty_possible_to_measure);

        // this.settings.ignore_locations_selected_to_measure = localStorage.getItem('ignore_locations_selected_to_measure') == 'true';
        // this.setIgnoreLocationsSelectedToMeasure(this.settings.ignore_locations_selected_to_measure);

        this._devstroupeConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: DevstroupeConfig) => {

                this.config = config;
            });
    }


    ngOnDestroy(): void
    {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    setIgnoreQtyPossibleToMeasure(value:boolean): void
    {
        this._settingsService.requestSettingsUpdate('ignore_qty_possible_to_measure',
            value ? 'true' : 'false'
        );
    }

    setIgnoreLocationsSelectedToMeasure(value:boolean): void
    {
        this._settingsService.requestSettingsUpdate('ignore_locations_selected_to_measure',
            value ? 'true' : 'false'
        );
    }

    ngAfterViewInit(): void {
        this._settingsService.settingsToggleSubject.subscribe(()=> {
            this.settingsDrawer.toggle();
            setTimeout(() => {
                this._changeDetectorRef.markForCheck();
            });
        });
        this.settingsDrawer.close()
    }
}
