<!-- Loading bar -->
<devstroupe-loading-bar></devstroupe-loading-bar>

<!-- Navigation -->
<devstroupe-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [appearance]="navigationAppearance"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.PCP"
    [opened]="!isScreenSmall"
    id="navigation"
>
    <!-- Navigation header hook -->
    <ng-container devstroupeVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center justify-center h-16 bg-white mb-2">
            <div
                class="overflow-hidden transition-all duration-300 ease-out"
                [ngClass]="{
                    'max-w-56': opened || navigationAppearance == 'default',
                    'max-w-7': !opened && navigationAppearance != 'default'
                }"
            >
                <!-- <img
                    class="w-auto max-w-none"
                    src="assets/images/logo/logo-realiza-2.svg"
                    alt="Logo image"> -->
            </div>
        </div>
    </ng-container>
</devstroupe-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <div class="flex items-center pr-2 space-x-2">
            <!-- Navigation toggle button -->
            <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
            </button>
            <!-- Navigation appearance toggle button -->
            <!-- <button
                class="hidden md:inline-flex"
                mat-icon-button
                (click)="toggleNavigationAppearance()">
                <mat-icon [svgIcon]="'heroicons_outline:arrows-right-left'"></mat-icon>
            </button> -->
        </div>
        <!-- Components -->
        <div class="flex items-center justify-end pl-2 ml-auto min-w-1/3 sm:min-w-1/2 space-x-0.5 sm:space-x-2">
            <!-- <languages></languages> -->
            <!-- <devstroupe-fullscreen class="hidden md:block"></devstroupe-fullscreen> -->
            <!-- <search [appearance]="'bar'"></search> -->
            <!-- <shortcuts></shortcuts> -->
            <!-- <messages></messages> -->
            <!-- <notifications></notifications> -->
            <!-- <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>
            </button> -->
            <div class="uppercase font-semibold flex text-gray-700 dark:text-secondary-500 ml-4">
                <button mat-icon-button [matTooltip]="isDark ? 'Claro' : 'Escuro'" (click)="setTheme(isDark ? 'light' : 'dark')">
                    <mat-icon [svgIcon]="isDark ? 'heroicons_outline:sun' : 'heroicons_outline:moon'"></mat-icon>
                </button>
                <button mat-icon-button [matTooltip]="isAuto ? 'Auto Ligado' : 'Auto Desligado'" (click)="setThemeAuto()">
                    <mat-icon [svgIcon]="isAuto ? 'heroicons_solid:bolt' : 'heroicons_outline:bolt'"></mat-icon>
                </button>
            </div>
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!-- <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Devstroupe &copy; {{currentYear}}</span>
    </div> -->
</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->
