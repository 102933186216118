import { Injectable, OnDestroy } from '@angular/core';
import { SocketServer } from 'app/app.config';
import { BehaviorSubject, lastValueFrom, Observable, Subject } from 'rxjs';
import { ApiService } from '../apis/api.service';

export const DEFAUL_SETTINGS = {
    ignore_qty_possible_to_measure: false,
    ignore_locations_selected_to_measure: false,
};
@Injectable({
    providedIn: 'root',
})
export class SettingsService implements OnDestroy {
    private _settings: any = DEFAUL_SETTINGS;
    user;
    public settingsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(this._settings);

    // constructor(private socket: SocketServer, private api: ApiService) {
    //     console.log("constructor SettingsService");

    //     ApiService.userCurrent.subscribe((user) => {
    //         this.user = user
    //         if(this.user) {
    //             // this.getSettings();
    //         }
    //     })
    //     this.socket.fromEvent("settingUpdated").subscribe(async (updatedSetting: any) => {
    //         console.log("settingUpdated change");

    //         this._settings = this.settingsSubject.getValue() || {};
    //         this._settings[updatedSetting.key] = this.formatValue(updatedSetting.value);
    //         this.settings = this._settings;
    //     });

    //     this.socket.fromEvent("disconnect").subscribe(() => {
    //         console.log("Disconnected from server");
    //     });
    // }

    private _unsubscribeAll: Subject<void> = new Subject<void>();

    ngOnDestroy(): void {
        console.log('Destroying service connection');
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    public get settings(): Observable<any> {
        return this.settingsSubject.asObservable();
    }

    public set settings(value: any) {
        this.settingsSubject.next(value);
    }
    // getSettings() {
    //     this.api.getSub("settings/list",{limit:'all'}).subscribe((settings) => {
    //         settings.items.forEach(item => {
    //             this._settings[item.key]= this.formatValue(item.value)
    //         });

    //         this.settings = this._settings;
    //     });
    // }

    // Método para solicitar atualizações de configuração
    public requestSettingsUpdate(key, value): void {
        // this.api.postSub("settings",{key,value}).subscribe((setting) => {
        // });
    }
    public settingsToggleSubject: BehaviorSubject<any> = new BehaviorSubject(null);

    public toggle() {
        return this.settingsToggleSubject.next(null);
    }

    formatValue(value) {
        if (value === 'true') {
            return true;
        } else if (value === 'false') {
            return false;
        } else {
            return value;
        }
    }
}
