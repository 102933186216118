<div class="modalbox success col-sm-8 col-md-6 col-lg-5 center animate">
    <div class="icon">
        <span>
            <mat-icon class="w-28 h-28 text-white" [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
        </span>
    </div>
    <h1 class="font-semibold line-clamp-3 text-2xl leading-tight my-2">ERRO!</h1>
    <p class="text-2xl font-medium leading-tight" *ngIf="_data.title">{{ _data.title }}</p>
    <br />
    <p
        class="text-lg font-normal leading-tight"
        style="overflow: auto !important; max-height: calc(100vh - 18rem)"
        *ngIf="_data.message"
        [innerHTML]="_data.message"
    ></p>
</div>
