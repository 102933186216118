import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { UserModel } from 'app/modules/user/user.model';
import { ApiService } from 'app/shared/apis/api.service';
import { of, switchMap, take } from 'rxjs';

export const typeCustomerGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router = inject(Router);
    const allowedCustomerTypes = route.data['type_customer'] as string[];
    return ApiService.userCurrent.pipe(
        take(1),
        switchMap((user: UserModel) => {
            if (user.type === 'CLIENT' && allowedCustomerTypes.includes(user.type_user_customer)) {
                return of(true);
            }

            if (user.type !== 'CLIENT') {
                return of(true);
            }

            return of(router.parseUrl(''));
        }),
    );
};
