import { DatePipe, NgClass, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { DevstroupeFullscreenComponent } from '@devstroupe/components/fullscreen';
import { DevstroupeLoadingBarComponent } from '@devstroupe/components/loading-bar';
import { DevstroupeNavigationItem, DevstroupeNavigationService, DevstroupeVerticalNavigationComponent } from '@devstroupe/components/navigation';
import { DevstroupeMediaWatcherService } from '@devstroupe/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserComponent } from 'app/layout/common/user/user.component';
import { UserModel } from 'app/modules/user/user.model';
import { ApiService } from 'app/shared/apis/api.service';
import { debounceTime, filter, Subject, switchMap, takeUntil } from 'rxjs';

@Component({
    selector: 'classic-layout',
    templateUrl: './classic.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        DevstroupeLoadingBarComponent,
        DevstroupeVerticalNavigationComponent,
        MatButtonModule,
        MatIconModule,
        DevstroupeFullscreenComponent,
        UserComponent,
        NgIf,
        RouterOutlet,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        DatePipe,
        NgClass,
    ],
})
export class ClassicLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: Navigation;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    navigationData: DevstroupeNavigationItem[];

    config = {
        logo: 'assets/images/logo/trevo-logo-white.png',
        class: 'bg-primary-500',
    };
    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _devstroupeMediaWatcherService: DevstroupeMediaWatcherService,
        private _devstroupeNavigationService: DevstroupeNavigationService,
    ) {}
    currentDateTime: Date = new Date();
    private intervalId: any;
    searchInputControl: UntypedFormControl = new UntypedFormControl();
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation data
        this._navigationService.navigation$.pipe(takeUntil(this._unsubscribeAll)).subscribe((navigation: Navigation) => {
            this.navigation = navigation;
            this.setNavigationData();
        });
        this._activatedRoute.data.pipe(takeUntil(this._unsubscribeAll)).subscribe(({ config }) => {
            if (config) {
                this.config = config;
            }
        });

        this.searchInputControl.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(350),
                switchMap((query) => {
                    this._router.navigate([], {
                        relativeTo: this._activatedRoute,
                        queryParams: {
                            text: query,
                        },
                        queryParamsHandling: 'merge',
                    });
                    return query;
                }),
            )
            .subscribe();

        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll),
            )
            .subscribe((event) => {
                if (!this._activatedRoute.snapshot.queryParams['text']) {
                    this.searchInputControl.setValue('', { emitEvent: false });
                }
            });
        // Subscribe to media changes
        this._devstroupeMediaWatcherService.onMediaChange$.pipe(takeUntil(this._unsubscribeAll)).subscribe(({ matchingAliases }) => {
            // Check if the screen is small
            this.isScreenSmall = !matchingAliases.includes('md');
        });
        this.updateDateTime();
        this.intervalId = setInterval(() => this.updateDateTime(), 1000);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        clearInterval(this.intervalId);
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    private updateDateTime(): void {
        const now = new Date();
        // const options: Intl.DateTimeFormatOptions = {
        //     weekday: 'long',
        //     year: 'numeric',
        //     month: 'long',
        //     day: 'numeric',
        //     hour: '2-digit',
        //     minute: '2-digit',
        //     second: '2-digit',
        //     hour12: false,
        // };
        this.currentDateTime = now;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._devstroupeNavigationService.getComponent<DevstroupeVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    setNavigationData(): void {
        if (!this.navigation) return;

        if (this._activatedRoute.routeConfig.path == 'pce') {
            this.navigationData = this.navigation.PCE;
        } else {
            this.navigationData = this.navigation.PCP;
        }
    }
}
