import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { companyResolver } from './shared/resolvers/company.resolver';
import { UserTypeGuard } from './core/auth/guards/user-type.guard';
import { typeCustomerGuard } from './core/auth/guards/user-type-customer.guard';

const PCEConfig = () => ({
    logo: 'assets/images/logo/pec_dark.png',
    class: 'bg-modules-pec text-gray-800',
});
const PCPConfig = () => ({
    logo: 'assets/images/logo/pcp_white.png',
    class: 'bg-modules-pcp',
});

const dataPCE = {
    module: 'pce',
};

const dataPCP = {
    module: 'pcp',
};
// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    // { path: '', pathMatch: 'full', redirectTo: 'pcp/ordens-producao' },
    { path: '', pathMatch: 'full', redirectTo: 'helper-module' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    // { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'pcp/ordens-producao' },
    { path: 'not-found-company', loadChildren: () => import('app/modules/auth/not-found-company/not-found-company.routes') },
    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes') },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes') },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes') },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes') },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes') },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes') },
            { path: 'helper-module', loadChildren: () => import('app/modules/auth/helper-module/helper-module.routes') },

            // { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes') },
        ],
    },

    // Admin routes
    {
        path: 'pcp',
        canActivate: [AuthGuard, UserTypeGuard],
        canActivateChild: [AuthGuard, UserTypeGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
            config: PCPConfig,
        },
        data: {
            ...dataPCP,
            type: ['USER'],
        },
        children: [
            {
                path: 'usuarios',
                data: {
                    ...dataPCP,
                },
                loadChildren: () => import('app/modules/user/user.routes'),
            },
            {
                path: 'funcoes',
                data: {
                    ...dataPCP,
                },
                loadChildren: () => import('app/modules/pcp/function/function.routes'),
            },
            {
                path: 'status',
                data: {
                    ...dataPCP,
                },
                loadChildren: () => import('app/modules/pcp/status/status.routes'),
            },
            {
                path: 'motivos-perda',
                data: {
                    ...dataPCP,
                },
                loadChildren: () => import('app/modules/pcp/reason-loss/reason-loss.routes'),
            },
            {
                path: 'ordens-producao',
                data: {
                    ...dataPCP,
                },
                loadChildren: () => import('app/modules/pcp/production-order/production-order.routes'),
            },
            {
                path: 'fundo-investimento',
                data: {
                    ...dataPCP,
                },
                loadChildren: () => import('app/modules/pcp/investment-fund/investment-fund.routes'),
            },
            {
                path: 'empresas',
                data: {
                    ...dataPCP,
                },
                loadChildren: () => import('app/modules/pce/company/company.routes'),
            },
            {
                path: 'tipo_de_anexo',
                data: {
                    ...dataPCP,
                },
                loadChildren: () => import('app/modules/pcp/type-order-files/type-order-files.routes'),
            },
        ],
    },
    {
        path: 'pce',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
            config: PCEConfig,
        },
        data: {
            ...dataPCE,
        },
        children: [
            {
                path: 'usuarios',
                canActivateChild: [typeCustomerGuard],
                canActivate: [typeCustomerGuard],
                data: {
                    ...dataPCE,
                    type_customer: ['matriz', 'gestor'],
                },
                loadChildren: () => import('app/modules/user/user.routes'),
            },
            {
                path: 'empresas',
                data: {
                    ...dataPCE,
                },
                loadChildren: () => import('app/modules/pce/company/company.routes'),
            },
            {
                path: 'produtos',
                data: {
                    ...dataPCE,
                },
                loadChildren: () => import('app/modules/pce/product-company/product-company.routes'),
            },
            {
                path: 'relatorios',
                data: {
                    ...dataPCE,
                },
                loadChildren: () => import('app/modules/pce/relatorios/relatorio.routes'),
            },
            {
                path: 'pedidos',
                data: {
                    ...dataPCE,
                },
                loadChildren: () => import('app/modules/pce/order_product_user/order_product_user.routes'),
            },
        ],
    },
    //ecommerce routes
    {
        path: ':company_slug',
        canActivate: [AuthGuard],
        component: LayoutComponent, //hack pra ler o css do layout
        data: {
            layout: 'ecommerce',
        },
        canActivateChild: [AuthGuard],
        resolve: {
            initialData: initialDataResolver,
            company: companyResolver,
        },
        // children: [
        //     {
        //         path: '',
        //         component: LayoutEcommerceComponent, //hack pra ler o css do layout
        //     },
        // ],
        children: [{ path: '', loadChildren: () => import('app/modules/pce/ecommerce/ecommerce.routes') }],
    },
];
