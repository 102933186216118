import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DevstroupeHorizontalNavigationBasicItemComponent } from '@devstroupe/components/navigation/horizontal/components/basic/basic.component';
import { DevstroupeHorizontalNavigationDividerItemComponent } from '@devstroupe/components/navigation/horizontal/components/divider/divider.component';
import { DevstroupeHorizontalNavigationComponent } from '@devstroupe/components/navigation/horizontal/horizontal.component';
import { DevstroupeNavigationService } from '@devstroupe/components/navigation/navigation.service';
import { DevstroupeNavigationItem } from '@devstroupe/components/navigation/navigation.types';
import { HiddenOptions } from 'app/mock-api/common/navigation/data';
import { UserModel } from 'app/modules/user/user.model';
import { ApiService } from 'app/shared/apis/api.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'devstroupe-horizontal-navigation-branch-item',
    templateUrl: './branch.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        MatMenuModule,
        NgTemplateOutlet,
        NgFor,
        DevstroupeHorizontalNavigationBasicItemComponent,
        forwardRef(() => DevstroupeHorizontalNavigationBranchItemComponent),
        DevstroupeHorizontalNavigationDividerItemComponent,
        MatTooltipModule,
        MatIconModule,
    ],
})
export class DevstroupeHorizontalNavigationBranchItemComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_child: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() child: boolean = false;
    @Input() item: DevstroupeNavigationItem;
    @Input() name: string;
    @ViewChild('matMenu', { static: true }) matMenu: MatMenu;

    private _devstroupeHorizontalNavigationComponent: DevstroupeHorizontalNavigationComponent;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(private _changeDetectorRef: ChangeDetectorRef, private _devstroupeNavigationService: DevstroupeNavigationService) {}
    hiddenOptions: HiddenOptions = {
        user: null,
    };
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        ApiService.userCurrent.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: UserModel) => {
            if (user) {
                this.hiddenOptions = { user: user };
            }
            // Mark for check
            this._changeDetectorRef.markForCheck();
            this._changeDetectorRef.detectChanges();
        });
        // Get the parent navigation component
        this._devstroupeHorizontalNavigationComponent = this._devstroupeNavigationService.getComponent(this.name);

        // Subscribe to onRefreshed on the navigation component
        this._devstroupeHorizontalNavigationComponent.onRefreshed.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            // Mark for check
            this._changeDetectorRef.markForCheck();
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Trigger the change detection
     */
    triggerChangeDetection(): void {
        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}
