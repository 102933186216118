<devstroupe-loading-bar></devstroupe-loading-bar>

<div class="flex flex-col flex-auto w-full min-w-0">
    <div class="bg-yellow-400 p-4 z-10 flex justify-between items-center sticky top-0 w-full">
        <!-- Espaço vazio para garantir que a logo fique centralizada -->
        <div class="flex items-center gap-3 absolute">
            <a href="https://www.facebook.com/evobrindesoficial" target="_blank" rel="noopener noreferrer">
                <mat-icon class="icon-size-5 text-black" [svgIcon]="'feather:facebook'"></mat-icon>
              </a>
              <a href="https://www.instagram.com/evobrindesoficial/" target="_blank" rel="noopener noreferrer">
                <mat-icon class="icon-size-5 text-black" [svgIcon]="'feather:instagram'"></mat-icon>
              </a>
              <a
                href="https://www.google.com/maps/place/Evo+Brindes/@-18.8872719,-48.236008,935m/data=!3m2!1e3!4b1!4m6!3m5!1s0x94a4442c9eccdbe3:0x4d2a4bb99d4a3349!8m2!3d-18.8872719!4d-48.2334331!16s%2Fg%2F11c2nqt61c?entry=ttu&g_ep=EgoyMDI0MTIxMC4wIKXMDSoASAFQAw%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
              >
                <mat-icon class="icon-size-5 text-black" [svgIcon]="'feather:google'"></mat-icon>
              </a>
              <a href="https://www.tiktok.com/@evo_brindes" target="_blank" rel="noopener noreferrer">
                <mat-icon class="icon-size-5 text-black cursor-pointer" [svgIcon]="'feather:tiktok'"></mat-icon>
              </a>              
        </div>
        <div class="w-1/3">
            <!-- social icons -->
        </div>

        <!-- Logo do site -->
        <div class="flex justify-center w-1/3 cursor-pointer" [routerLink]="['/', this.company.route_ecommerce]">
            <img src="assets/images/logo/iconlogo-evo-brindes-preto.svg" class="h-14" />
        </div>

        <!-- Carrinho e Botão de Logout -->
        <div class="w-1/3 flex justify-end items-center space-x-4">
            <!-- Ícone do Carrinho -->
            <div class="flex flex-row items-center gap-2 cursor-pointer" (click)="goToCart()">
                <span>{{ currencyCart | currency : 'BRL' }}</span>
                <mat-icon svgIcon="heroicons_solid:shopping-cart" [matBadge]="countItems"></mat-icon>
            </div>

            <!-- Botão de Logout -->
            <!-- <button class="bg-white text-black px-4 py-2 rounded-lg" (click)="logout()">Logout</button> -->
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="goToRelatorios()">
                    <mat-icon>picture_as_pdf</mat-icon>
                    <span>Relatórios</span>
                </button>

                <button mat-menu-item (click)="logout()">
                    <mat-icon>logout</mat-icon>
                    <span>Sair</span>
                </button>
            </mat-menu>
        </div>
    </div>

    <div class="flex flex-col flex-auto">
        <router-outlet></router-outlet>
    </div>

    <footer class="bg-yellow-400 text-gray-600 p-4 mt-4">
        <div class="container mx-auto flex flex-col sm:flex-row items-center justify-around">
            <p class="text-sm mb-2 sm:mb-0 font-bold">
                <span>Desenvolvido por <a target="_blank" href="https://devstroupe.com" class="text-blue-800">Devstroupe Solutions</a></span>
                em parceria com
                <span class="font-bold"><a target="_blank" href="https://up2value.com" class="text-blue-800">Up2Value</a></span
                >.
            </p>
            <p class="text-sm font-bold">
                <a target="_blank" href="https://evobrindes.com.br" class="text-blue-800">Evobrindes</a> © 2024. Todos os direitos reservados.
            </p>

            <!-- <p class="text-sm font-bold">Plataforma <span class="text-blue-800"> Trevo</span></p> -->
        </div>
    </footer>
</div>
