import { registerLocaleData } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import { ApplicationConfig, Injectable, LOCALE_ID } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading } from '@angular/router';
import { provideDevstroupe } from '@devstroupe';
import { appRoutes } from 'app/app.routes';
import { provideAuth } from 'app/core/auth/auth.provider';
import { provideIcons } from 'app/core/icons/icons.provider';
import { provideTransloco } from 'app/core/transloco/transloco.provider';
import { mockApiServices } from 'app/mock-api';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { provideEnvironmentNgxCurrency, NgxCurrencyInputMode } from 'ngx-currency';
import localePt from '@angular/common/locales/pt';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntl } from './core/custom-paginator';
import { Socket, SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from 'environments/environment';
import { LIGHTBOX_CONFIG, LightboxConfig } from 'ng-gallery/lightbox';
import { register } from 'swiper/element/bundle';
const configSocket: SocketIoConfig = {
    options: { autoConnect: true, transports: ['websocket'], reconnection: true, reconnectionDelay: 1000, timeout: 5000, requestTimeout: 5000 },
    url: environment.apiUrlSocket,
};
const configSocket2: SocketIoConfig = {
    options: {
        autoConnect: true,
        transports: ['websocket'],
        reconnection: true,
        reconnectionDelay: 1000,
        timeout: 5000,
        requestTimeout: 5000,
    },
    url: environment.apiUrl.split('/').slice(0, 3).join('/'),
};
import { provideToastr } from 'ngx-toastr';
import { provideEcharts } from 'ngx-echarts';
import { ErrorImageDirective } from './shared/directives/erro-image.directive';

register();
@Injectable()
export class SocketChatTroupe extends Socket {
    constructor() {
        super(configSocket);
    }
}

@Injectable()
export class SocketServer extends Socket {
    constructor() {
        super(configSocket2);
    }
}

registerLocaleData(localePt, 'pt-BR');

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        provideHttpClient(),
        provideRouter(appRoutes, withPreloading(PreloadAllModules), withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })),

        // Material Date Adapter
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'D',
                },
                display: {
                    dateInput: 'D',
                    monthYearLabel: 'LLL yyyy',
                    dateA11yLabel: 'DD',
                    monthYearA11yLabel: 'LLLL yyyy',
                },
            },
        },

        // Transloco Config
        provideTransloco(),
        provideEcharts(),

        // Devstroupe
        provideAuth(),
        provideIcons(),
        provideDevstroupe({
            mockApi: {
                delay: 0,
                services: mockApiServices,
            },
            devstroupe: {
                layout: 'classic',
                scheme: 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme: 'theme-default',
                themes: [
                    {
                        id: 'theme-default',
                        name: 'Default',
                    },
                    {
                        id: 'theme-brand',
                        name: 'Brand',
                    },
                    {
                        id: 'theme-teal',
                        name: 'Teal',
                    },
                    {
                        id: 'theme-rose',
                        name: 'Rose',
                    },
                    {
                        id: 'theme-purple',
                        name: 'Purple',
                    },
                    {
                        id: 'theme-amber',
                        name: 'Amber',
                    },
                ],
            },
        }),
        provideEnvironmentNgxMask(),
        provideEnvironmentNgxCurrency({
            align: 'left',
            allowNegative: false,
            allowZero: true,
            decimal: ',',
            thousands: '.',
            precision: 2,
            prefix: 'R$ ',
            suffix: '',
            nullable: false,
            min: 0,
            max: null,
            inputMode: NgxCurrencyInputMode.Financial,
        }),
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR',
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'pt-BR',
        },
        {
            provide: MatPaginatorIntl,
            useClass: CustomMatPaginatorIntl,
        },
        {
            provide: LIGHTBOX_CONFIG,
            useValue: {
                panelClass: 'mediaLigthbox',
            } as LightboxConfig,
        },

        SocketServer,
        SocketChatTroupe,
        provideToastr(),
    ],
};
