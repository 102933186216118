import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { ErrorDialogComponent } from 'app/shared/dialogs/error-dialog/error-dialog.component';
import { BehaviorSubject, catchError, lastValueFrom, Observable, switchMap, take, tap } from 'rxjs';
import { ApiService } from 'app/shared/apis/api.service';
import { CompanyModel } from './company.model';
import { IFilterPagination } from 'app/shared/interfaces/filter-interface';
import { IPagination } from 'app/shared/interfaces/pagination.dto';
import { AuthService } from 'app/core/auth/auth.service';
import { BaseService } from 'app/core/services/base.service';
import { map } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class CompanyService extends BaseService<CompanyModel> {
    endpoind = 'company';

    static _company$ = new BehaviorSubject<CompanyModel>(null);

    constructor(api: ApiService, _matDialog: MatDialog) {
        super(api, _matDialog, 'company');
    }

    getBySlug(slug: string | number): Observable<CompanyModel> {
        return this.api.getSub<CompanyModel>(`${this.endpoint}/slug/${slug}`).pipe(
            tap((company) => {
                CompanyService.setCompany(company);
                return company;
            }),
            // catchError((error) => {
            //     this.openDialogErro({
            //         title: `Erro ao Buscar ${this.endpoint}!!`,
            //         message: this.getErrorMessage(error),
            //     });
            //     throw error;
            // }),
        );
    }

    static get company$(): Observable<CompanyModel> {
        return CompanyService._company$.asObservable();
    }

    static setCompany(company: CompanyModel): void {
        CompanyService._company$.next(company);
    }
}
