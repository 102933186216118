import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { UserModel } from 'app/modules/user/user.model';
import { ApiService } from 'app/shared/apis/api.service';
import { of, switchMap, take } from 'rxjs';

// export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
// {
//     const router: Router = inject(Router);

//     // Check the authentication status
//             // If the user is not authenticated...
//     if ( !inject(AuthService).check() )
//     {
//         // Redirect to the sign-in page with a redirectUrl param
//         const redirectURL = state.url === '/sign-out' ? '' : `redirectURL=${state.url}`;
//         const urlTree = router.parseUrl(`sign-in?${redirectURL}`);

//         return of(urlTree);
//     }

//     // Allow the access
//     return of(true);
// };

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router: Router = inject(Router);
    const authService = inject(AuthService);

    return ApiService.userCurrent.pipe(
        take(1),
        switchMap((user: UserModel) => {
            // Check the authentication status
            if (!authService.check()) {
                // Redirect to the sign-in page with a redirectUrl param
                const redirectURL = state.url === '/sign-out' ? '' : `redirectURL=${state.url}`;
                const urlTree = router.parseUrl(`sign-in?${redirectURL}`);
                return of(urlTree);
            }

            // Allow access to sign-in and sign-out routes without further checks
            if (state.url.includes('sign-in') || state.url.includes('sign-out')) {
                return of(true);
            }

            if (user.type === 'CLIENT') {
                if (user.company?.has_ecommerce && user.company.route_ecommerce) {
                    const ispcpRoute = state.url.includes('pcp');

                    if (ispcpRoute) {
                        const urlTree = router.parseUrl(user.company.route_ecommerce);
                        return of(urlTree);
                    } else {
                        return of(true);
                    }
                } else {
                    const urlTree = router.parseUrl('not-found-company');
                    return of(urlTree);
                }
            }

            // Check if the user is an admin and the route
            if (!user.function?.is_admin) {
                if (!state.url.includes('ordens-producao') && !state.url.includes('client')) {
                    const urlTree = router.parseUrl(`pcp/ordens-producao`);
                    return of(urlTree);
                }
            }

            // Allow the access
            return of(true);
        }),
    );
};
