import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class AuthService {
    private _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {}

    // /**
    //  * Check the authentication status
    //  */
    check() {
        return this.isAuthenticated();
    }
    login(auth) {
        return this._httpClient.post(environment.apiUrl + '/auth/login', auth).toPromise();
    }

    logout() {
        // this._api.user.next(null);
        let user = JSON.parse(localStorage.getItem('user'));

        const versao = localStorage.getItem('versao');
        const scheme = localStorage.getItem('scheme');
        const construction = localStorage.getItem('construction');
        // if (user.ambiente_id != environment.ambiente_id){
        //     localStorage.clear();
        //     location.reload();
        //     localStorage.clear();
        // }
        localStorage.clear();
        if (versao) {
            localStorage.setItem('versao', versao);
        }
        if (scheme) {
            localStorage.setItem('scheme', scheme);
        }
        location.reload();
        // this._router.navigate(['compact/account/login']);
    }
    logoutNotReload() {
        // this._api.user.next(null);
        let user = JSON.parse(localStorage.getItem('user'));

        const versao = localStorage.getItem('versao');
        const scheme = localStorage.getItem('scheme');
        const construction = localStorage.getItem('construction');
        // if (user.ambiente_id != environment.ambiente_id){
        //     localStorage.clear();
        //     location.reload();
        //     localStorage.clear();
        // }
        localStorage.clear();
        if (versao) {
            localStorage.setItem('versao', versao);
        }
        if (scheme) {
            localStorage.setItem('scheme', scheme);
        }
        // this._router.navigate(['compact/account/login']);
    }

    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post(environment.apiUrl + '/reset-password/create', { email });
    }

    resetPassword(token: string, senha: string): Observable<any> {
        return this._httpClient.put(environment.apiUrl + '/reset-password/' + token, { senha });
    }

    getToken(token: string): Observable<any> {
        return this._httpClient.get(environment.apiUrl + '/reset-password/' + token);
    }

    get token() {
        return localStorage.getItem('token');
    }
    get tokenRefresh() {
        return localStorage.getItem('tokenRefresh');
    }
    get rememberMe() {
        return localStorage.getItem('rememberMe');
    }

    isAuthenticated() {
        return Boolean(this.token);
    }

    set token(token: string) {
        localStorage.setItem('token', token);
    }
    set tokenRefresh(token) {
        localStorage.setItem('tokenRefresh', token);
    }
    set rememberMe(value: string) {
        localStorage.setItem('rememberMe', value);
    }
}
