import { CommonModule, NgClass, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation, HostListener, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { DevstroupeFullscreenComponent } from '@devstroupe/components/fullscreen';
import { DevstroupeLoadingBarComponent } from '@devstroupe/components/loading-bar';
import { DevstroupeNavigationService, DevstroupeVerticalNavigationComponent } from '@devstroupe/components/navigation';
import { DevstroupeMediaWatcherService } from '@devstroupe/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserComponent } from 'app/layout/common/user/user.component';
import { Subject, filter, take, takeUntil } from 'rxjs';
import { DevstroupeConfigService } from '../../../../../@devstroupe/services/config/config.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DevstroupeConfig } from '@devstroupe/services/config';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, NgModel } from '@angular/forms';

@Component({
    selector: 'dense-layout',
    templateUrl: './dense.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        DevstroupeLoadingBarComponent,
        DevstroupeVerticalNavigationComponent,
        MatButtonModule,
        MatIconModule,
        DevstroupeFullscreenComponent,
        UserComponent,
        NgIf,
        RouterOutlet,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        NgClass,
        MatSelectModule,
        MatFormFieldModule,
        CommonModule,
        FormsModule,
    ],
})
export class DenseLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: Navigation;
    navigationAppearance: 'default' | 'dense' = 'dense';
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    isDark: boolean = false;
    isAuto: boolean = false;
    opened: boolean = false;
    config: DevstroupeConfig;
    url: string = '';
    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _devstroupeMediaWatcherService: DevstroupeMediaWatcherService,
        private _devstroupeNavigationService: DevstroupeNavigationService,
        private _devstroupeConfigService: DevstroupeConfigService,
        private cdr: ChangeDetectorRef,
    ) {}
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ngOnInit(): void {
        this.url = this._router.url;
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll),
            )
            .subscribe(() => {
                this.url = this._router.url;
                this.cdr.detectChanges();
            });

        this._devstroupeConfigService.config$.pipe(takeUntil(this._unsubscribeAll)).subscribe((config: DevstroupeConfig) => {
            // Store the config
            // this.config = config;
            this.isDark = config.scheme == 'dark' || (config.scheme == 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches);
            this.isAuto = config.scheme == 'auto';
        });
        // Subscribe to navigation data
        this._navigationService.navigation$.pipe(takeUntil(this._unsubscribeAll)).subscribe((navigation: Navigation) => {
            this.navigation = navigation;
        });

        // Subscribe to media changes
        this._devstroupeMediaWatcherService.onMediaChange$.pipe(takeUntil(this._unsubscribeAll)).subscribe(({ matchingAliases }) => {
            // Check if the screen is small
            this.isScreenSmall = !matchingAliases.includes('md');
            // Change the navigation appearance
            this.navigationAppearance = this.isScreenSmall ? 'default' : 'dense';
        });

        document.getElementById('navigation').addEventListener('mouseenter', () => this.onMouseenter());
        document.getElementById('navigation').addEventListener('mouseleave', () => this.onMouseLeave());
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._devstroupeNavigationService.getComponent<DevstroupeVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    /**
     * Toggle the navigation appearance
     */
    toggleNavigationAppearance(): void {
        this.navigationAppearance = this.navigationAppearance === 'default' ? 'dense' : 'default';
    }

    setThemeAuto() {
        if (this.isAuto && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            this.setTheme('dark');
        } else {
            this.setTheme('auto');
        }
    }

    setTheme(scheme) {
        this._devstroupeConfigService.config = { scheme };
    }

    onMouseenter = () => {
        this.opened = true;
    };

    onMouseLeave = () => {
        this.opened = false;
    };
}
