<devstroupe-drawer class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999" fixed [mode]="'over'" [name]="'settingsDrawer'" [position]="'right'" #settingsDrawer>

    <div class="flex flex-col w-full overflow-auto bg-card dark:bg-dark-400">
        <div class="flex flex-row items-center px-6 h-20 min-h-20 text-white bg-primary">
            <mat-icon class="icon-size-7 text-current" [svgIcon]="'heroicons_solid:cog-8-tooth'"></mat-icon>
            <div class="ml-3 text-2xl font-semibold tracking-tight">Configurações</div>
            <button class="ml-auto" mat-icon-button (click)="settingsDrawer.close()">
                <mat-icon
                    class="text-current"
                    [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            </button>
        </div>

        <div class="flex flex-col p-6">

            <!-- Scheme -->
            <div class="text-md font-semibold text-secondary">Medições</div>
            <div class="grid grid-cols-3 gap-3 justify-items-start mt-6">
                <div class="sm:col-span-3 mb-5 flex justify-start items-center">
                    <mat-slide-toggle (change)="setIgnoreQtyPossibleToMeasure($event.checked)" [checked]="this.settings.ignore_qty_possible_to_measure" [color]="'primary'">Permitir medir insumo mais do que foi evoluído?</mat-slide-toggle>
                </div>
                <div class="sm:col-span-3 mb-5 flex justify-start items-center">
                    <mat-slide-toggle (change)="setIgnoreLocationsSelectedToMeasure($event.checked)" [checked]="this.settings.ignore_locations_selected_to_measure" [color]="'primary'">Permitir medir insumo mesmo que não esteja vinculado aos locais?</mat-slide-toggle>
                </div>
            </div>
        </div>

    </div>

</devstroupe-drawer>
