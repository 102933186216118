import { MatDialog } from '@angular/material/dialog';
import { Injectable, Optional } from '@angular/core';
import { ErrorDialogComponent } from 'app/shared/dialogs/error-dialog/error-dialog.component';
import { catchError, Observable } from 'rxjs';
import { ApiService } from 'app/shared/apis/api.service';
import { snakeCase } from 'lodash';
import { IFilterPagination } from 'app/shared/interfaces/filter-interface';
import { IPagination } from 'app/shared/interfaces/pagination.dto';

@Injectable({
    providedIn: 'root',
})
export class BaseService<T> {
    constructor(public api: ApiService, public _matDialog: MatDialog, public endpoint: string, @Optional() public name_id: string = null) {}

    isCreating(item: T): boolean {
        return !item[this.getIdName];
    }

    list(filter?: IFilterPagination<T>): Observable<IPagination<T>> {
        return this.api.getSub<IPagination<T>>(`${this.endpoint}/list`, filter);
    }

    create(item: T): Observable<T> {
        return this.api.postSub<T>(`${this.endpoint}/create`, item).pipe(
            catchError((error) => {
                this.openDialogErro({
                    title: `Erro ao Cadastrar ${this.endpoint}!!`,
                    message: this.getErrorMessage(error),
                });
                throw error;
            }),
        );
    }

    getById(id: string | number): Observable<T> {
        return this.api.getSub<T>(`${this.endpoint}/${id}`).pipe(
            catchError((error) => {
                this.openDialogErro({
                    title: `Erro ao Buscar ${this.endpoint}!!`,
                    message: this.getErrorMessage(error),
                });
                throw error;
            }),
        );
    }

    update(item: T): Observable<T> {
        const id = item[this.getIdName];

        return this.api.putSub<T>(`${this.endpoint}/${id}`, item).pipe(
            catchError((error) => {
                this.openDialogErro({
                    title: `Erro ao Atualizar ${this.endpoint}!!`,
                    message: this.getErrorMessage(error),
                });
                throw error;
            }),
        );
    }

    delete(item: T): Promise<void> {
        const id = item[this.getIdName];
        return this.api.delete<void>(`${this.endpoint}/${id}`).catch((error) => {
            this.openDialogErro({
                title: `Erro ao Deletar ${this.endpoint}!!`,
                message: this.getErrorMessage(error),
            });
            throw error;
        });
    }
    deleteSub(id: string | number): Observable<void> {
        return this.api.deleteSub<void>(`${this.endpoint}/${id}`).pipe(
            catchError((error) => {
                this.openDialogErro({
                    title: `Erro ao Deletar ${this.endpoint}!!`,
                    message: this.getErrorMessage(error),
                });
                throw error;
            }),
        );
    }

    openDialogErro(item: { title: string; message: string }): void {
        this._matDialog.open(ErrorDialogComponent, {
            panelClass: 'notification-dialog',
            width: '500px',
            data: item,
        });
    }

    getErrorMessage(error: any): string {
        return (
            error.error?.errors?.map((e: any) => e.descricao).join('<br />') ||
            error.error.message ||
            error.error.sqlMessage ||
            error.error.descricao ||
            'Erro desconhecido'
        );
    }

    get getIdName(): string {
        return this.name_id || `${snakeCase(this.endpoint)}_id`;
    }
}
